import React from 'react'
import { Link } from 'react-router-dom'
const RulesRegulations = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
    <li><Link to="/home"> Home</Link> </li>
     <li> Information</li>
     <li> Rules & Regulations   </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
        <div className="row">
          <div className="col-lg-12">
              <h1> Rules & Regulations  </h1>
                <p>The rules and regulations of the school are drawn to ensure a smooth functioning and provide an outline of code of conduct to the parents and children during their stay at school. Abiding by these instructions will be in the best interest of all concerned, a broad contours of which are given below:</p>

                 <ul className="mainlist">
                    <li>Every parent must read the school prospectus before they enter in contract with the school or before signing the declaration by the parents . As a proof of acceptance of the whole content of the prospectus/rules and regulations the declaration form must be signed and submitted prior to the admission of your child.</li>
                    <li>Any and every parent who seeks admission for their ward must necessarily look into the facilities provided by the school prior to the registration/admission. Since the school is managed by the minority Christian catholic community in the true spirit of Jesus Christ and his mission, no state of art facilities will be made available to the wards or no undue demands for the same shall be considered after the admission is taken. Once admission is taken it will be deemed satisfactory to the choice of the one admitted.</li>
                    <li>Every parent of the child admitted to Sacred Heart Convent Sr. Sec. School, Jagraon shall be provided with special parent - identity card with photo and complete detail.</li>
                    <li>Persons holding approved school identity card on behalf of their child shall be permitted entry to the school campus or be entertained by the school or office staff in any of the dealings.</li>
                    <li>The identity card of the Guardian/Parent/Student is the entry pass to the school campus. The same shall remain the property of the school and its validity will be for the specified period only. On the expiry of which parents and students must submit the same to the Principal and obtain a fresh identity card which is duly attested by the Principal.</li>
                    <li>The Parent's Identity card must have the photos of mother, father/guardian and child with their complete detail respectively.</li>
                    <li>Identity card provided by the school must be produced at the main first main gate and at the gate of the control room (2nd gate) and make an entry in the register kept at the control room with a special note of the purpose of the visit. Persons deputed at the gate, office premises or at Enquiry are authorized to have a check on your card and if needed physically to examine whether one carries anything that violates the school discipline and safety. It is mandatory for every I-card holder to produce the card before the officers assigned by the school to have any dealing. Non-card holders shall in no way be allowed entry to the campus nor shall be entertained in any manner.</li>
                    <li>The identity card, if found misused/transferred/handed over to others who are not registered/recognized by the school, shall be forfeited by the issuing authority.</li>
                    <li>Each student must possess an identity card issued by the school. It should be worn around neck whenever he/she is in the school campus and be made available for verification whenever demanded by the school authority/staff.</li>
                    <li>Under criminal procedure code all parent/guardian are strictly prohibited to bring any sharp-edged instruments or weapons of any kind to the school campus.</li>
                    <li>You are cautioned that the entire school campus is in the CCTV surveillance. Any act Violative of the code of conduct and discipline of the school shall be liable to penal procedures.</li>
                    <li>School diary is also the main information register cum rule book. It contains every aspect pertaining to the school, its curriculum, rules and regulations, manner of functioning, various prayers and conduct of programmes etc. Parent/Guardian must have a routine checkup of the school dairy of their ward to know the day today information from the school.</li>
                    <li>Every student should bring the school dairy daily. Any and every communication from the school office or information or request is primarily conveyed to the parents/guardians through the school dairy by the Principal/Teachers. If the parents/guardians are asked to meet the Principal/Teachers, they must do it without fail.</li>
                    <li>Students are expected to reach the school campus five minutes before the first bell for morning assembly and prayer. Those who are late must obtain permission from the concerned person in charge, who makes entries in the dairies of late comers before entering the school campus. After making three late arrival entries in the concerned dairy or register, Rs 20/- will be charged as fine for every subsequent late arrival from the late corner.</li>
                    <li>The warning bell before the beginning of the assembly is a signal for students to go to their respective classroom in perfect silence.</li>
                    <li>All pupils are expected to be present for the assembly during which they pray together.</li>
                    <li>The attendance roll will be taken at the beginning of the two sessions.</li>
                    <li>No students shall be allowed to go out or to be taken out of the school compound without obtaining the ‘out pass’ from the Principal. The out pass shall not be issued unless the Principal is satisfied with the genuineness of the matter and the accompanying person.</li>
                    <li>Personal cleanliness and grooming is expected of every student. The school uniform must be clean, well pressed and complete without any type of decorations. Students coming to the school with untidy dress, hair etc. will be fined and if not corrected will be asked to bring their parents to explain the reason.</li>
                    <li>Boys and girls are provided with separate lavatories. No boys or girls are allowed to use lavatories other than what is specially designed and allotted to them.</li>
                    <li>The Principal has the right to confiscate books, newspaper, periodicals, mobile phone, cameras, iPods, and any other Electronic devices or any items which may be considered objectionable.</li>
                    <li>The academic session of the school will be from March to February. The medium of instruction and expression is in English within the school campus. Punjabi, the mother tongue is taught from LKG and Hindi, the national language from class 1. All students must speak English within the school Campus.</li>
                    <li>Money should not be lent or borrowed or articles exchanged between the students.</li>
                    <li>No collection of money for any purpose whatsoever is to be started and no meeting, demonstration, party or picnic may be held without previous sanction to the Principal.</li>
                    <li>No pupil should bring razor, blade, or any sharp instruments or weapons unwarranted to the school.</li>
                    <li>Gifts/Presents to the members of the staff or other demonstration in their honour also require prior sanction of the Principal.</li>
                    <li>Any pupil desiring to be exempted from PT, games or any other activities in the school must produce a doctor’s certificate to the effect that he/she is physically unfit to take part therein.</li>
                    <li>Children have no permission to attend parties or to go the cinema or to go to friend’s house on their way back home from the school.</li>
                    <li>All pupils should have their books in a canvas or leather bag.</li>
                    <li>Care must be taken for all school property and no pupil should scratch or spoil the desks or chairs or damage any school furniture, write or draw anything on the wall or wall tiles, or in any way damage things belonging to others. Damage done even by accident should be reported at once to the class teacher or to the Principal. Any damage done must be made good by the one who does it. Anyone who sees something damaged should report the matter even if he/she does not know who has done it.</li>
                    <li>Everyone is advised not to waste water and electricity.</li>
                    <li>Use of plastic in the campus is strictly prohibited. All and every waste is to be deposited in the dust bins only.</li>
                    <li>All letters and correspondence addressed to pupils are subjected to the scrutiny of the principal.</li>
                    <li>The school is not responsible for articles or money lost. It is not advisable to bring valuable articles like expensive watches, fountain pens, electrical, electronic or digital gadgets etc. to school. Pupils are advised not to wear costly ornaments or wrist watches in the school.</li>
                    <li>Parents and students are prohibited in running, playing, shouting, whistling, hooting, hauling and yelling inside the school building.</li>
                    <li>Parents are not permitted to use any filthy, abusive or arrogant languages in the school campus; penal procedures will be initiated against such persons.</li>
                    <li>Entry of the parents during the school hours are strictly restricted to the sit out/ waiting hall/ parlour and counter premises only, except on special and permitted occasions like P.T meet and office hours.</li>
                    <li>Parents and guardians are not permitted to walk into class – rooms or interview teachers without the permission of the principal.</li>
                    <li>Parents are not allowed to entertain their ward in the school with food, drink, or medicine etc. after the assembly bell.</li>
                    <li>The name, Admission number, standard and section of the pupil should be clearly marked on all his/her belongings.</li>
                    <li>The students should behave in a refined manner whenever they go; they should always remember that the school is judged by their conduct. They should always greet their teachers, elders and friends in a respectful, pleasant and audible tone of voice whenever they meet them in school or outside. They should be gentle and polite to their companions and others.</li>
                    <li>Pupils shall be cheerful in their obedience to their parents and teachers giving them always the respect and affection that is their due and accepting their guidance and correction with gratitude.</li>
                    <li>Cycles must be kept locked at the cycles stand only. Students should not ride around the school compound on bicycles. While entering or leaving the school gate, one must walk instead of riding. Students who do not possess a valid driving license are not allowed to bring Motor vehicles of any description to the school.</li>
                    <li>Students who come to the school under the care of servants should never leave before they arrive. In case of delay they should report to the school office.</li>
                    <li>Those who go home alone should not loiter about the way. They should be prompt in returning home. Students are warned not to buy anything especially eatables from street vendors or to eat anything provided by any unknown or unauthorized persons.</li>
                    <li>In order to bring in discipline and have mastery over misspending in the life of students. Parents are advised to facilitate and provide with the required or desired food and minerals to their children in a suitable Tiffin box.</li>
                    <li>No unknown or unauthorized or unregistered person shall be permitted to meet/contact or entertain children in any manner during the school hours.</li>
                    <li>Students will not be called to answer phone calls during class hours, unless it is very urgent.</li>
                    <li>The principal has the right to suspend or request the withdrawal of any pupil without having to state any reason provided that he/she judges that such a step is necessary in the interest of the school.</li>
                    <li>There is a suggestion box for parents kept in front of the office. The school welcome any suggestion and advice which is positive and constructive for the welfare of the children and the school. But however, the school cannot guarantee to implement them immediately. Please own your suggestions by signing your letter (kept secret/ confidential)</li>
                    <li>Those parents/children who cause any agitation and incite others to do so against school or join with any anti-school elements to disturb the peaceful atmosphere and smooth functioning of the school, plotting against the larger interest of the school, instigating parents or general public, abetting to commit a crime against the school or its management within or outside school which causes damage to persons, reputation or good will of the school forfeit the right to continue in the school. Those students will be dismissed without giving any other reason.</li>
                    <li>Strict disciplinary action shall be initiated against anyone found misusing the service or privileges offered by the school. They shall be barred from availing such benefits with immediate effect.</li>
                    <li>Strict disciplinary/ penal/ criminal action shall be initiated against any parents/others who forge any of the documents, circulars, notices, I-cards, bills, receipts etc. issued by the principal/management or using the name, Fame, Good Will, Title, Seal and forged signatures of the school authority for any purposes.</li>
                    <li>The management has the right to take disciplinary action against students/parents who do not comply with rules of conduct and discipline of the school rules and requirements as published in school prospectus, school dairy or made known through circulars from time to time.</li>
                    <li>The school has the right to teach any subject that is required and approved by the Board of education both compulsory and optional.</li>
                    <li>Parents are requested not to contact the school staff with any issues except during the office hours. Contacting the staff at odd hours in their residence is strictly forbidden; all school matters should be dealt in the school only.</li>
                    <li>Just because one child of yours is admitted in the school does not in any way guarantee admission to all other children of your own family or of your relations.</li>
                    <li>Parents/Guardians are welcome to meet the principal from 09:00am to 11:00am in summer and 10:00am to 12:00 noon in winter season on all working days.</li>
                    <li>In order to inculcate in the little young minds of children school insists in its words the spirit of virtues like cooperation, togetherness, compassion, mutual love and care, love for the needy and less privileged, charity to the suffering fellow men, respect to the teachers, cultures and country etc. All students are expected to participate and contribute to the common initiatives programmed by the school to promote charity, entertainment programmes as under taken by the school from time to time.</li>
                    <li>In order to provide opportunities for exhibiting or discovering the hidden talents and abilities of the student the school organizes certain celebrations of special importance which include Director’s day, Principal’s Day, Teacher’s Day, Annual Day, Sports Day, inter school or interstate competitions, Independence day, Republic Day, Christmas, Rangoli etc. and also work of charity as per the demand placed by the given circumstances. In this regard each child may be asked to donate or contribute a sum as decided and informed to every parent by the school.</li>       
                    <li>Please remember, education also means character formation and we need to discuss the matters with you. As per instructions from the council, value education and character formation need to be given prime importance during a child’s years of schooling. Hence forth, the remarks registered in your child’s “student’s Log Book” will also be entered in their Progress Report. We need the Parent’s co-operation in correcting the child’s behavior through open dialogue between the teachers and the parents on days allotted for the PTM (Parent Teacher Meet).</li>       
                    <li>Disciplinary Action: Since the school management has strictly instructed its staff to avoid all kinds of corporal punishment, it has become dire need to discipline the students only with the co-operation of the parents. On any serious in disciplinary behavior of a ward a “Letter Of Information” will be sent to the concerned parents and if the child does not make any improvement after three chances of receiving the ‘ Letter of information’ and the corrections given in consultation with the parents in the light of the letter, a child may be asked to discontinue his/her studies in this school.</li>       
                    <li>Periodical Reports and the Report Book will keep parents/guardians informed of the progress of their wards. These reports are to be signed and returned in time as given in school dairy.</li>      
                    <li>The school does not provide conveyance to the students. The Transport of the children to and from the school is entirely the responsibility of the parents. Parents are to ensure the safety and security of their children outside the school campus and thus should entrust the care and transportation of their children to trusty worthy persons after a thorough enquiry of the person and inspection of the means of transport and its concerned documents. The school will not entertain any complaint on this matter or be responsible in any way.</li>    
                    <li>Special classes, sports and games, cultural and literary activities may be organized on Saturdays as and when required. Participation will be deemed compulsory, in such cases.</li>       
                    <li>Special remedial classes are offered to all weak children at free of cost. Parents are called to comply with the demand placed by the Principal in this regard. Parents are to arrange means of conveyance to their child if he/she is availing this benefit.</li>      
                    <li>Co-curricular activities and outdoor activities such as class picnics, camp and study-tours shall be compulsory for all and expenses will be charged from the students.</li>      
                    <li>No student is permitted to bring cell phone to the school complex. Parents and teachers are required to switch off their mobile’s phones in the school building.</li>     
                    <li>Duplicate copies of Transfer Certificate are ordinarily not issued. In special cases such certificates will be issued, after verification of the case and on the written application by the persons concerned, on payment of Rs. 200/-</li>     
                    <li>A student who is caught using unfair means in the examination will be awarded zero in the concerned paper and it could lead to his dismissal.</li>     
                    <li>Duplicate copy of the School Dairy will be issued only by the Principal, after verification, on a payment of Rs. 200/- Children must take care to bring this daily to school and also to safeguard it.</li>     
                    <li>No physical violence or verbal violence is permitted in the school. If anyone found using such means, he/she will be suspended with immediate effect and an enquiry will be conducted which may lead to one’s dismissal if proved guilty.</li>    
                    <li>Every child admitted to the school is encouraged to contact the principal’s office or any teaching staff of his/her choice in case of any violence, harassment, misuse, abuse or cruelty done against him/her by the members of the family; and the matter will reported to the commission for protection and welfare of children or to the nearest police station.</li>
                    <li>Allotting of sections/Houses and shuffling, as and when required, is fully at the discretion of the school authorities and no request for a particular section/house or changing of section/house is entertained, unless it is found very essential.</li>           
                    <li>The tendency of the students to go to private tuition is strongly discouraged. The school teachers shall not engaged themselves in private tuition for the children of the same school or to canvas any child for the same, except with the permission of the Principal.</li>         
                    <li>Parent-Teacher Meeting (PTM)is to be attended by all parents/guardians of the wards. Those students whose parents/guardians have not attended the PTM, without consent from the Principal, due to any unavoidable reasons, will not be allowed to attend the classes unless and until a reasonable explanation by their parents/guardians be given to the principal or to the person appointed on behalf of the principal.</li>          
                    <li>Every parent must bring the fee – card on the day of PTM and produce it before the class teacher/teacher-in-charge/appointed staff if demanded.</li>  
                    <li>While sending letters or making remittance of fees or inquiries, please note the full name (as written in the admission form) of the child. His/her class and Roll Number/Admission No. / Code No. is very important to enter because many students are there in a class/school with the same name.</li>         
                    <li>Parents and guardians are requested to notify the school of any change in their address and phone numbers.</li>  
                    </ul> 
                            </div>
                            </div>
                    
                    </div>
                    </div>
    </>
  )
}

export default RulesRegulations
