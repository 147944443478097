import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getTopper } from '../Service/Api';

const ToppersISC = ({ data: propData }) => {
  const [data, setData] = useState(propData || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getTopper();
        setData(topperData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (!propData) {
      fetchData();
    }
  }, [propData]);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
                    <li><Link to="/home"> Home</Link> </li>
          <li>Topper Class 12<sup>th</sup></li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Topper Class 12<sup>th</sup></h1>
            </div>
            <div className="col-lg-6">
              <div className="chooseyear">
                <select id="chooseddlYear">
                  <option value="Two">2024</option>
         
                </select>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div id="showTwo" className="myDiv1 selected_year">
            <div className="topperssec">
              {data?.length > 0 ? data?.filter((item) => item.class === "XII").map((item, index) => (
                <div key={index} className="topperdiv">
                  <div className="topperper"><p>{item.division}</p></div>
                  <div className="topperdivimg">
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid" alt="Sacred Heart Convent School, Jagraon" />
                  </div>
                  <div className="topperdivdesc">
                    <p className="toppername">{item.name}<span>{item.stream}</span></p>
                  </div>
                </div>
              )) : (
                <p>No data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToppersISC;
