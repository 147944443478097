import React from 'react';
import { Link } from 'react-router-dom';
import android from '../Assets/Images/android.png';
import ios from '../Assets/Images/ios.png';

const Pphelp = () => {
  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/home">Home</Link></li>
          <li>Parent Portal Guidelines</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Parent Portal Guidelines</h1>
            </div>
          </div>
          <div className="erp_sec">
            <h2>CampusCare10x Application Guidelines & Instructions</h2>
            
            
            
            <p className="details">1. Login to the portal <Link to="https://entab.online/shcjpb" target="_blank">https://entab.online/shcjpb</Link> using the login credentials</p>

            <p className="details">2. Click on the 'Pay Online' button on the left menu bar</p>
            <p className="details">3. Select the Installment and click on the 'Proceed to Pay' button</p>
            <p className="details">4. Click on 'Pay now' button and select your convenient mode of payment (Debit/Credit cards, Internet Banking, UPI/QR, and Wallet).</p>
            <p className="details">5. After successful payment, you will be redirected back to the parent portal where you can download the free receipts of payment made from the 'Fee' section.</p>
            <div className="clr10"></div>
            <div>
              <h4 className="guideheading">Mobile App</h4>
              <p className="details">1. Via Mobile App</p>
              <p className="details">2. Download App</p>
              <p className="details">iOS: CampusCare10X on App Store: <Link to="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank"><img src={ios} className="img-fluid app_new" alt="Download on App Store" /></Link></p>
              <p className="details">Android: CampusCare10X on Google Play - <Link to="https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN" target="_blank"><img src={android} className="img-fluid app_new" alt="Download on Google Play"/></Link></p>
            </div>
            <div className="clr25"></div>
            <div>
              <h4 className="guideheading">Payment Steps:</h4>
              <p className="details">1. Install and open the CampusCare10X app. Enter the school code: SJBSJL.</p>
              <p className="details">2. Log in successfully, then tap on the 'Fees' icon.</p>
              <p className="details">3. Choose the installment and press 'Proceed to Pay'.</p>
              <p className="details">4. Select 'Pay now' and your payment method.</p>
              <p className="details">5. Post-payment, the app redirects you for receipt download.</p>
            </div>
            <div className="clr10"></div>
            <div className="clr25"></div>
            <div className="assistance">
              <h4 className="guideheading">Please call or write for assistance:</h4>
              <p className="details">PARENT HELP DESK (8:00 AM to 8:00 PM), E-Mail: <Link to="mailto:parentdesk@entab.in" className="parent-link">parentdesk@entab.in</Link></p>
            </div>
            <div className="clr25"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pphelp;
