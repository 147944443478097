import styled from'styled-components'
const NewsEventHeading = styled.div`
 p{
    color: #545454;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 24px;
    margin-bottom: 6px;
    font-family: 'Oleo Script', system-ui;
    letter-spacing: 1px;
 }
 span{
    color: #d9b840;
}
h2{
    font-size: 35px;
    color: #fff;
    margin-bottom:23px;
}
@media (max-width: 1600px){
h2 {
font-size: 32px;
}
p {
font-size: 20px;
}
}
@media (max-width: 1200px){
    h2 {
        font-size: 27px;
        }
}


`
export default NewsEventHeading
