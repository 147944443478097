import React, { useEffect, useState } from 'react';
import { SlideshowLightbox } from 'lightbox.js-react';
import { Link, useNavigate } from 'react-router-dom';
import { getEventsinner } from '../Service/Api';

const Events = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getEventsinner();
            setData(galleryData);
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log("events_data", data);
    }, [data]);

    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];

    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };


    const emptyArray = [
        { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "events.jpg" },
        { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "events.jpg" },
        { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "events.jpg" },
        { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "events.jpg" },
    ];

    return (
        <>
            <div className="innerslide">
                <ul className="breadcrumb">
                <li><Link to="/home"> Home</Link> </li>
                    <li> Events </li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1> Events </h1>
                        </div>
                    </div>
                    <div className="row tabs-dynamic">
                <div className="col-md-3">
                    <div className="count-val">
                        <p>Total Count: </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="month-selection">
                        <select className="yearSelection">
                            <option className="2024" selected>2024</option>
                            <option className="2023">2023</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="month-selection">
                        <select className="monthSelect">
                            <option value="0">All Month</option>
                            <option value="1">Jan</option>
                            <option value="2">Feb</option>
                            <option value="3">Mar</option>
                            <option value="4">Apr</option>
                            <option value="5">May</option>
                            <option value="6">Jun</option>
                            <option value="7">Jul</option>
                            <option value="8">Aug</option>
                            <option value="9">Sep</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="searchBox">
                        <input type="text" id="myInput" name="name" autocomplete="off" placeholder="Search Here.." />
                    </div>
                </div>
            </div>
                    <div className="row years year2024 showYear">
                        {/* Render data */}
                        {data?.length > 0 ? data?.map((item, index) => (         
                            <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" data-month="1">
                                <div className="eventbox">
                                    <div className="eventboximg">
                                        <div className="eventattch">
                                            <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank">
                                                <i className="bi bi-paperclip"></i>
                                            </Link>
                                        </div>
                                        <SlideshowLightbox>
                                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images[0]}`} className="img-fluid border-img w-full rounded" alt="Sacred Heart Convent School, Jagraon" />
                                        </SlideshowLightbox>
                                    </div>
                                    <div className="eventboxdesc">
                                        <div className="eventpgh">
                                            <h5>{item.title}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="eventdate">
                                            <span><i className="bi bi-alarm"></i> {item.time}</span>
                                            <span><i className="bi bi-calendar"></i> {item.date}</span>
                                            <span><i className="bi bi-geo-alt"></i> {item.venue}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))

                
                        :emptyArray.map((item, index) => (
                            <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" data-month="1">
                                <div className="eventbox">
                                    <div className="eventboximg">
                                        <div className="eventattch">
                                            <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} target="_blank">
                                                <i className="bi bi-paperclip"></i>
                                            </Link>
                                        </div>
                                        <SlideshowLightbox>
                                            <img src={item.attachments} className="img-fluid border-img w-full rounded" alt="Sacred Heart Convent School, Jagraon" />
                                        </SlideshowLightbox>
                                    </div>
                                    <div className="eventboxdesc">
                                        <div className="eventpgh">
                                            <h5>{item.title}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="eventdate">
                                            <span><i className="bi bi-alarm"></i> {item.time}</span>
                                            <span><i className="bi bi-calendar"></i> {item.date}</span>
                                            <span><i className="bi bi-geo-alt"></i> {item.venue}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="load-more">
                        <div className="next">Show More</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Events;
