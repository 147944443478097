import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getVideo } from '../Service/Api';

const VideoGallery = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await getVideo();
      setData(datas);
    };
    getData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { title: "Video Title", date: "DD/MM/YYYY"},   
    { title: "Video Title", date: "DD/MM/YYYY"},   
    { title: "Video Title", date: "DD/MM/YYYY"},   
  ];

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/home"> Home</Link> </li>
          <li>  Gallery </li>
          <li> Video Gallery </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1> Video Gallery </h1>
            </div>
            <div className="col-lg-6">
              <div className="chooseyear">
                <select id="chooseddlYear">
                  <option selected="" value="Two">2023</option>
                  <option value="One">2022</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div id="showTwo" className="myDiv1 selected_year">
            <div className="row">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="video-gallery">
                      <Link to={item.videoUrl} target="_blank">
                        <i className="bi bi-play-circle-fill"></i>
                        <div>
                          <h3>{item.title}</h3>
                          <p>{new Date(item.date).toLocaleDateString('en-GB')}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                emptyArray.map((data, index) => (
                  <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="video-gallery">
                      <Link to="#" target="_blank">
                        <i className="bi bi-play-circle-fill"></i>
                        <div>
                          <h3>{data.title}</h3>
                          <p>{data.date}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoGallery;
