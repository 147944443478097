import React from 'react'
import Mobile_slides from '../Assets/Images/Mobile-slides.gif'
import communication from '../Assets/Images/communication.gif'
import ParentPortal from '../Assets/Images/Parent-Portal.gif'
import ManageData from'../Assets/Images/Manage-Data.gif'
import { Link } from 'react-router-dom'
const ErpSoftware = () => {
    return (
       <>
        <div className="innerslide">
        <ul className="breadcrumb">
        <li><Link to="/home"> Home</Link> </li>
            <li> School ERP Software </li>
            </ul>
        </div>
        <div className="innersec">
        <div className="container">
         <div className="row">
           <div className="col-lg-12">
                 <h1> School ERP Software </h1>
            </div>
         </div>
             <div className="erpbox">
                            <div className="erpboxdesc">
                            <p class="details"> Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <Link to="/https://www.entab.in/school-management-erp.html"> <b>ERP Software:</b></Link> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <Link to="/https://www.entab.in/school-communication-portals.html"> <b> communication portal</b></Link> and 
                            <Link to="/https://www.entab.in/mobile-apps.html">
                               <b> mobile apps</b>
                             </Link>.<br/>
                            <br/>
                            The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with <Link to="/https://www.entab.in/"> <b>Entab: CampusCare®</b></Link> and look forward to receive the much more in technological front. </p>
                                <div className="erpboxdescpopup">
                                        <div className="erppopup">
                                            <h4 className="imgheading"><Link to="/https://www.entab.in/school-management-erp.html"> Manage Data</Link></h4>
                                            <img id="myImg" src={ManageData} alt="School Management System, Management ERP Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop"/>
                                            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                <img src={ManageData} alt="School Management System, Management ERP Software" className="img-fluid"/>
                                                <h5>School Management System, Management ERP Software</h5>   
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="erppopup">
                                            <h4 className="imgheading"><Link to="/https://www.entab.in/school-communication-portals.html#PPC"> Parent Portal</Link></h4>
                                            <img id="myImg1" src={ParentPortal} alt="Entab Parent Portal, School Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"/>
                                            <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                <img src={ParentPortal} alt="Entab Parent Portal, School Software" className="img-fluid"/>
                                                <h5>Entab Parent Portal, School Software</h5>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div className="erppopup">
                                <h4 className="imgheading"><Link to="/https://www.entab.in/school-communication-portals.html#PPC"> Communication Portal</Link></h4> 
                                        <img id="myImg2" src={communication} alt="School Communication Portal" className="animation  center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop2"/>
                                        <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                            <img src={communication} alt="School Communication Portal" className="img-fluid"/>
                                            <h5>School Communication Portal</h5>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
  
                                </div>
                            </div>
                       </div>
                       <div className="erpboximg">
                       <h4 className="imgheading"><Link to="/https://www.entab.in/mobile-apps.html">CampusCare</Link></h4>
                   <center><Link to="/https://www.entab.in/mobile-apps.html"> <img src={Mobile_slides} className="img-fluid" alt="School ERP Software, Mobile App, School Management Software, Software for School"/></Link> </center>
               </div>
              </div>
           </div>
      </div>
      </>
    )
  }
  
export default ErpSoftware