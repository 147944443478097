import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getNews } from "../Service/Api";
import { Link } from "react-router-dom";

const News = () => {
  var settings = {  
    autoplay: true,
    adaptiveHeight: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await getNews();
      setData(result);
    };
    fetchData();
  }, []);

  const monthsOfYear = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const handleMonth = (month) => {
    return monthsOfYear[month - 1];
  };

  const emptyArray = [
    { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },   
    { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
    { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
  ];

  return (
    <Slider {...settings}>
      {data?.length > 0 ? (
        data.map((item, index) => (
          <div key={index}>
            <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank">
              <div className="news-blk">
                <div className="date">
                  <h3>
                    {new Date(item.fromDate).getDate()}
                    <span>
                      {handleMonth(new Date(item.fromDate).getMonth())}
                    </span>
                  </h3>
                </div>
                <div className="content">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            </Link>
          </div>
        ))
      ) : (
        emptyArray.map((data, index) => (
          <div key={index}>
            <div className="news-blk">
              <div className="date">
                <h3>
                  {data.day}
                  <span>{data.month}</span>
                </h3>
              </div>
              <div className="content">
                <h3>{data.title}</h3>
                <p>{data.description}</p>
              </div>
            </div>
          </div>
        ))
      )}
    </Slider>
  );
};

export default News;
