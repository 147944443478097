import React from 'react'
import { Link } from 'react-router-dom'
 const MandatoryDisclosure = () => {
  return (
     <>
            <div className="innerslide">
            <ul className="breadcrumb">
            <li><Link to="/home"> Home</Link> </li>
            <li> Mandatory Public Disclosure </li>
        </ul>
        </div>
        <div className="innersec">
        <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <h1> Mandatory Public Disclosure</h1>
            </div>
        </div> 
        <div className="row">
            <div className="col-lg-12">
                    <h6>GENERAL INFORMATION</h6> 
        <div className="table-responsive maintable"> 
        <table className="table"> 
        <thead> 
            <tr>
            <th width="2%;">S.No.</th>
            <th>INFORMATION</th>
            <th>DETAILS</th>
            </tr> 
            </thead>
            <tbody> 
            <tr>
            <td>1</td> <td>NAME OF THE SCHOOL </td> <td>Sacred Heart Convent School , Jagraon</td> </tr> 
        <tr>
        <td>2</td> <td>AFFILIATION NO.(IF APPLICABLE)</td> <td>1630199</td> </tr> 
        <tr>
        <td>3</td> <td>SCHOOL CODE (IF APPLICABLE)</td> <td>20189</td> </tr> 
        <tr>
        <td>4</td> <td>COMPLETE ADDRESS WITH PIN CODE</td> <td>G. T. Road, Aligarh, P. O - Jagraon Ludhiana (Punjab) - 142024</td> </tr> 
        <tr>
        <td>5</td> <td>PRINCIPAL NAME & QUALIFICATION: </td> <td>SR.ANCY JOSEPH MA, M.Sc, M.Ed</td> </tr> 
        <tr>
        <td>6</td> <td>SCHOOL EMAIL ID </td> <td>sacredheartjgrn@gmail.com</td> </tr> 
        <tr>
        <td>7</td> <td>CONTACT DETAILS (LANDLINE/MOBILE)</td> <td>Phone No : 01624-257465, Mob : 79862-18462</td> </tr> 
        </tbody> 
        </table> 
        </div>

            <h6>DOCUMENTS AND INFROMATION</h6> 
        <div className="table-responsive maintable"> 
        <table className="table"> 
        <thead> 
            <tr>
            <th width="2%;">S.No.</th>
            <th>DOCUMENTS/INFORMATION</th>
            <th>VIEW</th>
            </tr> 
            </thead>
            <tbody> 
            <tr>
            <td>1</td> <td>COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY </td> <td><Link className="a1" to='/pdf/Affliation.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>2</td> <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE</td> <td><Link className="a1" to='/pdf/SocietyReg.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>3</td> <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT</td> <td><Link className="a1" to='/pdf/NOC.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>4</td> <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE</td> <td><Link className="a1" to='/pdf/RTE.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>5</td> <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE</td> <td><Link className="a1" to='/pdf/BuildingSafety2023.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>6</td> <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY</td> <td><Link className="a1" to='/pdf/FireSafety.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>7</td> <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td> <td><Link className="a1" to='/pdf/WaterSafety2023.pdf' target="_blank"> VIEW </Link></td> </tr> 
        </tbody> 
        </table> 
        </div>
        <h6>DOCUMENTS AND INFROMATION</h6> 
        <div className="table-responsive maintable"> 
        <table className="table"> 
        <thead> 
            <tr>
            <th width="2%;">S.No.</th>
            <th>DOCUMENTS/INFORMATION</th>
            <th>VIEW</th>
            </tr> 
            </thead>
            <tbody> 
            <tr>
            <td>1</td> <td>FEE STRUCTURE OF THE SCHOOL</td> <td><Link className="a1" to='/pdf/FeeStructure2023.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>2</td> <td>ANNUAL ACADEMIC CALANDER.</td> <td><Link className="a1" to='/pdf/AcademicCalendar.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>3</td> <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)</td> <td><Link className="a1" to='/pdf/SMC.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>4</td> <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</td> <td><Link className="a1" to='/pdf/PTA.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>4</td> <td>LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABLILITY</td> <td><Link className="a1" to='/pdf/ThreeyearResult.pdf' target="_blank"> VIEW </Link></td> </tr> 
        <tr>
        <td>5</td> <td>SELF DECLARATION</td> <td><Link className="a1" to='/pdf/Self_Declaration.pdf' target="_blank"> VIEW </Link></td> </tr> 
        </tbody>
        </table>
        </div>
        <h6>RESULT CLASS X</h6> 
        <div className="table-responsive maintable"> 
        <table className="table"> 
        <thead> 
            <tr>
            <th width="2%;">S.No.</th>
            <th>YEAR</th>
            <th>NO. OF REGISTERED STUDENTS</th>
            <th>NO. OF STUDENTS PASSED</th>
            <th>PASS PERCENTAGE</th>
            </tr> 
            </thead>
            <tbody> 
            <tr>
            <td>1</td> <td>2019</td> <td>136</td> <td>136</td> <td>100 %</td> </tr> 
        <tr>
        <td>2</td> <td>2020</td> <td>138</td> <td>136</td> <td>98.55 %</td> </tr> 
        <tr>
        <td>3</td> <td>2021</td> <td>140</td> <td>140</td> <td>100 %</td> </tr> 
        <tr>
        <td>4</td> <td>2022</td> <td>160</td> <td>160</td> <td>100 %</td> </tr> 
        <tr>
        <td>5</td> <td>2023</td> <td>199</td> <td>199</td> <td>100 %</td> </tr> 
        </tbody>
        </table>
        </div>
        <h6>RESULT CLASS XII</h6> 
        <div className="table-responsive maintable"> 
        <table className="table"> 
        <thead> 
            <tr>
            <th width="2%;">S.No.</th>
            <th>YEAR</th>
            <th>NO. OF REGISTERED STUDENTS</th>
            <th>NO. OF STUDENTS PASSED</th>
            <th>PASS PERCENTAGE</th>
           </tr> 
            </thead>
            <tbody> 
            <tr>
            <td>1</td> <td>2019</td> <td>123</td> <td>112</td> <td>91.05 %</td> </tr> 
        <tr>
        <td>2</td> <td>2020</td> <td>117</td> <td>114</td> <td>97.43 %</td> </tr> 
        <tr>
        <td>3</td> <td>2021</td> <td>137</td> <td>137</td> <td>100 %</td> </tr> 
        <tr>
        <td>4</td> <td>2022</td> <td>140</td> <td>140</td> <td>100 %</td> </tr> 
        <tr>
        <td>5</td> <td>2023</td> <td>149</td> <td>149</td> <td>100 %</td> </tr> 
        </tbody>
        </table>
        </div>
        <h6>STAFF (TEACHING)</h6> 
        <div className="table-responsive maintable"> 
        <table className="table"> 
        <thead> 
            <tr>
            <th width="2%;">S.No.</th>
            <th>INFORMATION</th>
            <th>DETAILS</th>
            </tr> 
            </thead>
            <tbody> 
            <tr>
            <td>1</td> <td>PRINCIPAL</td> <td>1</td> </tr>
        <tr rowspan="6">
        <td>2 </td> <td>TOTAL NO. OF TEACHERS</td> <td> 124</td> </tr> 
        <tr>
        <td></td><td>PGT  </td> <td>17</td> </tr> 
        <tr>
        <td> </td> <td>TGT </td> <td>41</td> </tr> 
        <tr>
        <td> </td> <td>PRT  </td> <td>42</td> </tr> 
        <tr>
        <td> </td> <td>NTT  </td> <td>19</td> </tr> 
        <tr>
        <td> </td> <td> PET  </td> <td>5</td> </tr> 
        <tr>
        <td> </td> <td>NON TEACHING  </td> <td>9</td> </tr> 
        <tr>
        <td>3</td> <td>TEACHERS SECTION RATIO</td> <td>36/1</td> </tr> 
        <tr>
        <td>4</td> <td>DETAILS OF SPECIAL EDUCATOR</td> <td>0</td> </tr> 
        <tr>
        <td>5</td> <td>DETAILS OF COUNSELLOR AND WELNESS TEACHER</td> <td>1</td> </tr> 
        </tbody>
        </table>
        </div>
        <h6>SCHOOL INFRASTRUCTURE</h6> 
        <div className="table-responsive maintable"> 
        <table className="table"> 
        <thead> 
            <tr>
            <th width="2%;">S.No.</th>
            <th>INFORMATION</th>
            <th>DETAILS</th>
            </tr> 
            </thead>
            <tbody> 
            <tr>
            <td>1</td> <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)</td> <td>24205</td> </tr>
        <tr>
        <td>2 </td> <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQ FTMTR)</td> <td> 84  CLASS ROOMS,31.58m2</td> </tr> 
        <tr>
        <td>3</td> <td>NO. AND SIZE OF LABORATORIES INCLUDING  COMPUTER  LABS (IN SQ MTR)</td> <td>9 LAB , 47.38m2.</td> </tr> 
        <tr>
        <td>4</td> <td>INTERNET FACILITY (Y/N)</td> <td>YES</td> </tr> 
        <tr>
        <td>5</td> <td>NO. OF GIRLS TOILETS</td> <td>60</td> </tr> 
        <tr>
        <td>6</td> <td>NO. OF BOYS TOILETS</td> <td>60</td> </tr> 
        <tr>
        <td>7</td> <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</td> <td><Link className="a1" to="https://www.youtube.com/watch?v=S0JdscspZLM" target="_blank"> YOUTUBE </Link></td> </tr> 
        </tbody>
        </table> 
        </div>
            </div>
        </div>
        </div>
        </div>
     </>
  )
}

export default MandatoryDisclosure
