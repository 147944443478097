import  Styled  from 'styled-components'
const MainHeading = Styled.div`
    font-size: 24px;
    color: #e3c351;
    margin-bottom: 6px;
    font-family: 'Oleo Script', system-ui;
    letter-spacing: 1px;
     span{
        color: #d9b840;
    }
    h2{
        margin-bottom: 23px;
        color: #1c1b3b;
        font-size: 44px; 
    }
    @media (max-width: 1600px){
   h2 {
    font-size: 32px;
   }
   p {
    font-size: 20px;
    }
}
@media (max-width: 567px){
    h2 {
     font-size: 27px;
    }
 }
`
export default MainHeading