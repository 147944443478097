import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/SHSJ`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/SHSJ`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/SHSJ`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/SHSJ`);
    console.log(data)
    return data;
}

export const getBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/SHSJ`);
    console.log(data)
    return data;
}

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/SHSJ`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/SHSJ`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/SHSJ`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/SHSJ`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/SHSJ`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/SHSJ`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/SHSJ`, getHeader());
    return data;
} 

// export const getMedia = async() => {
//     const { data } = await axios.get(API_URL + `/api/media/SHSJ`, getHeader());
//     return data;
// } 

export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/SHSJ`, getHeader());
    return data;
} 

export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/SHSJ`,{name,admission},getHeader());
    return data;
}
