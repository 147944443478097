import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getSliders } from "../Service/Api";

function HomeSlider() {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 8000,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sliderData = await getSliders();
        setData(sliderData);
      } catch (error) {
        console.error("Error fetching slider data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const defaultSlides = [
    { attachment: "slide1.jpg", alt: "Placeholder Slide 1" },
    { attachment: "slide2.jpg", alt: "Placeholder Slide 2" }
  ];

  return (
    <div className="slider">
      <div className="slider-container">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Slider {...settings}>
            {data[0]?.attachments?.map((item, index) => (
              <div key={index}>
                <img
                  src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachment}`}
                  className="img-fluid"
                  alt={item.alt || "Slide Image"}
                />
              </div>
            )) || (
              defaultSlides.map((item, index) => (
                <div key={index}>
                  <img
                    src={item.attachment}
                    className="img-fluid"
                    alt={item.alt || "Slide Image"}
                  />
                </div>
              ))
            )}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default HomeSlider;
