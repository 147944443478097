import React from 'react'
import { Link } from 'react-router-dom'
const Alumni = () => {
  return (
         <>
         <div className="innerslide">
         <ul className="breadcrumb">
         <li><Link to="/home"> Home</Link> </li>
         <li> Alumni </li>
     </ul>
     </div>
     <div className="innersec">
     <div className="container">
     <div className="row">
         <div className="col-lg-12">
             <h1> Alumni Association  </h1>
             <h6>Aims & Objectives</h6>
             <p>To foster a sense of belonging towards the school among the former students and enable them to carry forward the vision and mission of the school in their personal and professional lives.To provide a forum for the of the alumni to interact among themselves, stay in touch with the school and work for the good of each other.To create and make use of a network of alumni members who can contribute to the growth and development of the school as well as help create successful careers for the students.</p>
             <h6>Roles & Functions</h6>
             <p>To build profiles of successful alumni of the school to serve as inspiration for the students and faculty. To build a database of all alumni members with their contact details and maintain a regular dialogue with them through emails or newsletters. To carry out an exit survey of the students for obtaining their feedback on their experiences, which can be used for carrying out continuous improvement in academic and administrative practices. To organise workshops/training programmes which could benefit the alumni members in their life goals.To establish web interface pages in social media such as facebook, twitter, linkedin, google+, etc. to facilitate greater interaction among the alumni members and with the current school administrative members.If you are interested to join us our alumni team, please fill the registration form and send to us on  <Link to="mailto:shcschoolalumni@gmail.com" className="click">shcschoolalumni@gmail.com</Link></p>
             
         </div>
     </div> 
     </div>
     </div>
         </>
  )
}

export default Alumni
