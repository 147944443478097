import React from 'react'
import pdf from'../Assets/Images/pdf.png'
import {Link} from'react-router-dom'
const Circular = () => {
  return (
      <>
      <div className="innerslide">
      <ul className="breadcrumb">
      <li><Link to="/home"> Home</Link> </li>
         <li> Information</li>
         <li> Circular </li>
        </ul>
        </div>
        <div className="innersec">
        <div className="container">
                <div className="row">
                <div className="col-lg-12">
                        <h1> Circular </h1>
                </div>
                </div>
                <p><b>Data will be updated soon</b></p>
                {/* <div className="row"> 
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src={pdf} alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src={pdf} alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src={pdf} alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src={pdf} alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src={pdf} alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                </div> */}
        </div>
        </div>
       
      </>
  )
}

export default Circular
