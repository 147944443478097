import React from 'react'
import { Link } from 'react-router-dom'
const Evaluation = () => {
  return (
     <>
     <div className="innerslide">
     <ul className="breadcrumb">
     <li><Link to="/home"> Home</Link> </li>
      <li> Information</li>
      <li> Evaluation  System </li>
  </ul>
</div>
<div className="innersec">
 <div className="container">
    <div className="row">
          <div className="col-lg-12">
               <h1> Evaluation  System </h1>
                 <p>It is our endeavour that evaluation should act as a positive input for improving the learning process and not as a deterrent. The report card of evaluation should reflect not only the scholastic aspects of the learner, but also life skills acquisition, personality attributes, behavioural attributes, personal interests and attitudes, values and proficiency in indoor as well as outdoor co-curricular activities etc. The overall education process should facilitate the scholar in unraveling the latent talents and reflect the true picture of the growth and profile of a young learner.</p>


                    <h6>CLASS I-X</h6>

                    <p>Our school aims of facilitating learning, guiding, developing skills, conforming to social expectations and building balanced personalities. Keeping in view, the above mentioned standards and abiding by the regulations laid down by C.B.S.E, our school is designed to provide academic challenge within a stimulating and exciting environment. This learning based approach enables students to make significant academic progress. A unique assignment and testing system has been developed in school at all levels which equips our students to face the more complex and demanding ‘real world’ of the 21st century.</p>

                    <p>Abiding by the regulations laid down by C.B.S.E., evaluation system from academic year 2018-19 will follow the new assessment structure. The assessment structure comprises of two terms -</p>

                    <div className="term">
                    <div className="termbox">
                    <p>Term -1 </p>

                    </div>
                    <div className="termbox">
                    <p>Term -2 </p>

                    </div>
                    </div>
                    <div className="row tablebg">
                                <div className="col-lg-6">
                                <h6>Examination Schedule (I-IX, XI) </h6>


                            <div className="table-responsive maintable">
                                    <table class="table">
                            <thead>
                                <tr>
                                    <th>Examination  </th>
                                    <th>Term  </th>
                                    <th>Month  </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Periodic Test-1</td>
                                    <td>Term-1</td>
                                    <td>May</td>
                                </tr>
                                <tr>
                                    <td>Half Yearly</td>
                                    <td>Term-1</td>
                                    <td>September</td>
                                </tr>
                                <tr>
                                    <td>Periodic test-2</td>
                                    <td>Term-2</td>
                                    <td>December</td>
                                </tr>
                                <tr>
                                    <td>Annual Exam</td>
                                    <td>Term-2</td>
                                    <td>February</td>
                                </tr>
                            </tbody>
                            </table>
                            </div>
                    </div>
                            
                                                <div className="col-lg-6">
                                <h6>Examination Schedule (X and XII) </h6>


                            <div className="table-responsive maintable">
                                    <table class="table">
                            <thead>
                                <tr>
                                    <th>Examination  </th>
                                    <th>Term  </th>
                                    <th>Month  </th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td>Periodic Test-1</td>
                                        <td>Term-1</td>
                                        <td>May</td>
                                    </tr>
                                    <tr>
                                        <td>Half Yearly</td>
                                        <td>Term-1</td>
                                        <td>September</td>
                                    </tr>
                                    <tr>
                                        <td>Periodic test-2</td>
                                        <td>Term-2</td>
                                        <td>December</td>
                                    </tr>
                                    <tr>
                                        <td>Annual Exam</td>
                                        <td>Term-2</td>
                                        <td>February</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                    </div>
                    </div>

                    <h6>Evaluation of Scholastic Subjects for Half Yearly and Yearly Exam (I-X)</h6>
                    <p>Each subject will be evaluated out of 100 marks which will be divided into 80 marks (Written Test) and 20 marks (Internal Assessment). Internal Assessment is further divided into: -</p>

                    <div className="term"><div className="termbox"> <p> Periodic Test - 10 Marks</p></div>
                    <div className="termbox"> <p> Subject Enrichment Activity - 5 Marks</p></div>
                    <div className="termbox"> <p> Notebook Maintenance - 5 Marks</p></div></div>


                    <h6>Periodic Test (I-VIII): -</h6>
                    <p>For Half Yearly Exam periodic test marks will be taken from Periodic Test – 1</p>

                    <h6>Periodic Test (IX-X)</h6>
                    <p>As there is no periodic system, periodic test marks will be calculated by taking average of the best two tests out of three written exams conducted i.e. Periodic Test – I, Half Yearly and Periodic Test – II</p>

                    <h6>Syllabus for Yearly Exam (I – X)</h6>
                    <div className="table-responsive maintable">
                    <table class="table">
                    <thead><tr>
                    <th>Class</th>
                    <th>Syllabus</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td>I-IV </td>
                    <td>  Entire syllabus covered after Half Yearly Exam.</td>
                    </tr>

                    <tr>
                    <td>V-VI</td>
                    <td>20% of Term -1 covering significant topics and entire syllabus covered after Half Yearly Exam.</td>
                    </tr>

                    <tr>
                    <td>VII-VIII</td>
                    <td>30% of Term -1 covering significant topics and entire syllabus covered after Half Yearly exam.</td>
                    </tr>

                    <tr>
                    <td>IX</td>
                    <td>Full Year syllabus.</td>
                    </tr>

                    <tr>
                    <td>X</td>
                    <td>  Full Year syllabus.</td>
                    </tr>



                    </tbody>
                    </table>
                    </div>

                    <h6>Classes XI – XII</h6>
                    <p>The syllabus is in accordance with CBSE guidelines. The school offers Science and Commerce stream in classes XI and XII. After careful career counselling and appropriate percentage in class X, various combination of subjects is offered to students to enable them to pursue a career of their choice later in life. Students are prepared regularly for CBSE exams by concentrating on the prescribed CBSE syllabus, assignments and monitoring programmes. Opportunities are provided at every level to the students for the exercise of their reflective powers, artistic abilities and practical work.</p>
                            </div>  
                        </div>

                    </div>
                    </div>
     </>
  )
}

export default Evaluation
