import React from 'react'
import { Link } from 'react-router-dom'
const OurHistory = () => {
  return (
    <>
    <div className="innerslide">
    <ul class="breadcrumb">
    <li><Link to="/home"> Home</Link> </li>
     <li>About Us</li>
     <li>Our History</li>
 </ul>
</div>
<div className="innersec">
<div className="container">
<div className="row">
     <div className="col-lg-12">
          <h1>Our History</h1>
     </div>
</div>
 <div className="row">
      <div className="col-lg-12">
         <div className="quotes">
            <p> <b> “The future belongs to those who have the courage and strength to fulfill their dreams.”</b></p>
         </div>
           <ul className="mainlist">
               <li>
             The Sacred Heart sisters, Delhi Province, started the <b>‘Sacred Heart Convent School’</b> in a rented building in Heera Bagh. Though the <b>‘Sacred Heart Convent School, Jagraon’</b> had a humble start in a rented building, it prospered beyond expectations. Mother Theresa Margaret, Sr. Josephine and all those who gladly undertook the trouble to initiate this humble seedling can proudly and delightedly look at this mighty institution today.
         </li>
         <li>
             Mr. Lohia a well known advocate was the key person who encouraged and inspired us to start a convent school here and took us around to locate the present plot where the school is situated. <b>On November 26, 1989</b>, the land was purchased from Mr. Sachedeva, a well known resident of Jagraon. It took one year to construct a few rooms in the school site. Meanwhile a small house was rented out in Hirabagh to start admissions and conduct classes. Another house was rented out for the sisters to stay in Hira bagh itself. The response from the residents of Jagraon was commendable. They placed their trust in us; though there was no building and facilities, they were willing to entrust their children to our care. We acknowledge with love the love and support of our benevolent parents today with a grateful heart.
         </li>
         <li>
             The first batch of students was registered to <b>L.K.G on February 12, 1990</b>. Thus, the school started on <b>15th April 1990</b>, with eighty six children with <b>Sr. Lylet as its pioneer Principal, Sr. Josephine, Sr. Daisy Nedumpuram and Sr. Albin </b> as the pioneer school community, trusting in the infinite Providence of God.
         </li>
         <li>
             The foundation stone of <b>‘Sacred Heart Convent School,’</b> Jagraon was laid by Rt. Rev. Bishop symporian Keeprath, the bishop of Jalandhar in April, 1990. How can we forget our benefactors like Mr. Saluja and Mr. BB Lohia a renowned advocate of Jagraon who ardently helped the management to acquire a piece of land for the institution.
         </li>
         <li>
             After a year, <b>in 1991</b>, the school was shifted to the present site. Since, it was a lonely place, for safety reasons, <b>'sisters'</b> residence was still at a rented house in Hirabagh itself. They shifted to the school in the year 1992. Finally, the much awaited day arrived and the school was shifted from the rented building to its new building on the G. T Road on 6th April, 1991. The then S.D.M of Jagraon, Mr. Iqbal Singh Inaugurated the school and institution praised the pioneers for setting an educational institution in the area for imparting quality education.
         </li>
         <li>
             The institution covered yet another milestone when it was affiliated to the CBSE board in Delhi in 2001. The first batch of X brought about a mood of Jubilation by bringing 100% result in the academic year 2001-02.
         </li>
         <li>
             The school progressed by leaps and bounds under the able guidance and dexterous administration of Rev. Sr. Lylet, Rev. Sr. Fausty, Rev. Sr .Cristo, Rev. Sr. Sherly,Rev. Sr. Ann Mary and the present Principal Rev. Sr. Jessy Joseph Naduparambil.
         </li>
         <li>
             The present Principal Rev. Sr. Jessy Joseph Naduparambil really put in her best as she visualized this school at a much higher level. Her vision came out to be a reality when the institution was upgraded from secondary level to the senior secondary level in 2011, when it was issued the <b>‘No objection Certificate’</b>.
         </li>
         <li>
             Rev. Fr. John the spiritual director of our school and the director of  Sacred Heart Convent School , BRS Nagar Ludhiana laid the foundation stone for the new building of the senior secondary wing in April 2011.
         </li>
         <li>
             The commencement of the senior secondary level classes was indeed a red letter day in the history of the institution. The school is efficiently running classes of medical, non medical and commerce stream. We feel proud to reveal that so far 14 batches of 10th and 3rd batches of 12th have brought laurels to their institution.
         </li>
         <li>
             Due to the tremendous progress of our alma mater the need for a new building was felt. Thus it came as a silver gift to the school the new K.G. block on the occasion of its silver jubilee.
         </li>
         <li>
             It has been rightly said,<b> “The ability to handle the responsibilities will by large determine the progress”</b>. In fact progress is the activity of today and assurance of tomorrow. Under the supervision of our Rev. Principals the school progressed along with its technical infrastructure. The school is proud of its well equipped library, Science labs, Mathematics lab, Social Science lab, Language lab, well furnished computer lab, Performing arts department, Sports department, the school band and many more that enhance the total beauty of this wonderful institution.
         </li>
         <li>
             A large number of co-curricular activities, house activities, club activities, celebrations, Sports activities, Core group activities are a part and parcel of the school calendar.
         </li>
         <li>
             The institution achieved its height of glory and excellence in every field when it was adorned with a silver crown i.e. when it celebrated its silver jubilee on <b>7th November, 2014 </b> in the presence of a galaxy of distinguished guests. The alumni meet added to the overall beauty of this function. The silver jubilee was in fact a time to pause and reflect on all the aspects of the mission. It signified a coming of age and maturity.
         </li>
         <li>
             The <b>‘Sacred Heart’</b> family has no doubt successfully crossed many milestones but with its perseverance and consistency it hopes to reach the peak of its mission keeping in mind the supreme motto of <b>“WISDOM, SERVICE AND LOVE”</b>.
         </li>

           </ul>
      </div>
 </div> 
</div>
</div>
    </>
  )
}

export default OurHistory
