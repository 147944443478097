import React from 'react'
import { Link } from 'react-router-dom'
const Admission = () => {
  return (
   <>
   <div className="innerslide">
   <ul className="breadcrumb">
              <li><Link to="/home"> Home</Link> </li>
    <li> Information</li>
    <li> Admission </li>
</ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
       <div className="col-lg-12">
             <h1> Admission </h1>
        </div>
     </div>
         <div className="row">
     <div className="col-lg-12">
            <div className="quotes">
                 <p><b>“Little drops of water, little grains of sand, make the mighty ocean and the pleasant land”</b></p>
            </div>
     </div>
</div>
</div>
</div>
   </>
  )
}

export default Admission
