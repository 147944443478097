import React, { useState } from 'react'
import { Link } from 'react-router-dom'
const Conduct = () => {
  return (
     <>
     <div className="innerslide">
     <ul className="breadcrumb">
     <li><Link to="/home"> Home</Link> </li>
      <li> Information</li>
      <li> Code of Conduct  </li>
  </ul>
</div>
<div className="innersec">
 <div className="container-fluid conductbox">
    <div className="col-lg-12">
               <h1> Code of Conduct </h1>
          </div>
         <div className="row">
  <div className="col-lg-6 col-md-12 col-12 bg2">
      <h6>Do's:-</h6>
      <ul className="mainlist">
           <li> Be polite and respectful to your parents, teachers, staff of the School and elderly citizens. </li>
           <li> Greet them whenever you happen to pass by them. </li>
           <li> Be friendly with each other in and out of School. </li>
           <li> Be punctual. </li>
           <li> Be neat and clean. </li>
           <li> Be humble in your success and chivalrous in your failures. </li>
           <li> Be honest, upright and truthful. </li>
           <li> Be co-operative and exhibit team spirit and positive attitude / conduct. </li>
           <li> Exhibit exemplary behaviour and show respect for the feelings of others. </li>
           <li> Be patient, tolerant and secular. </li>
           <li> Take pride in yourself, your School, your parents and your Nation. </li>
           <li> Be courteous, remember "Thank You" and "Please" are two very good words. </li>
           <li> Be thankful to God for every thing that He has given to you. </li>
           <li> Believe in yourself and have courage. Remember God is with you always and everywhere. </li>
           <li> Practice tolerance and acceptance of diverse races and cultures. </li>
           <li> Talk of reconciliation where there is injury. </li>
         
          
      </ul>
  </div>
  <div className="col-lg-6 col-md-12 col-12 bg3">
  
         <ul className="mainlist">
           <li> Be the first to forgive others; show magnanimity. </li>
           <li> Be always optimistic; hope for the best. </li>
           <li> Promote creativity and spontaneity in your work. </li>
           <li> Love values of work, respect, service, loyalty, dialogue, sharing and brotherhood. </li>
           <li> Always come forward and take up responsibilities, discover and develop your talents. </li>
           <li> “All work and no play make jack a dull boy”, goes a saying. Take part in extra curricular activities enthusiastically. </li>
           <li> Love your work as it enhances the dignity of the person. </li>
           <li> Even if you win or lose, participate in as many co curricular activities as possible. </li>
           <li> Seize every occasion to proclaim the benefits of Peace. </li>
           <li> No legacy is so rich as honesty. </li>
           <li> Be gentle with those who are younger than you. </li>
           <li> Always be concerned and helpful to the needs of the underprivileged. </li>
           <li> Always speak well of our School, management, teachers and School mates. </li>
           <li> Move gracefully and silently from your classNamees to other activity places such as play grounds, labs, hall etc. and back. </li>
           <li> Be courteous and sportsman-like with opposing teams and sports officers. </li>
           <li> While entering somebody’s room, knock the door first and gently seek prior permission. </li>
       </ul>
  </div>  
</div>
<br />
<div className="row">
 <div className="col-lg-6 col-md-12 col-12 bg2">
 <h6>Dont's:-</h6>
      <ul className="mainlist">
           <li> Do not cause hurt through your words or deeds. </li>
           <li> Do not be afraid to speak the truth or to accept mistakes. </li>
           <li> Do not damage or disfigure property of your School, public properties or at home. </li>
           <li> Do not use abusive language. </li>
           <li> Do not look down upon those who are less privileged, weak or physically challenged. </li>
           <li> Do not ill-treat animals or birds. </li>
           <li> Do not mock or ridicule others’ failures or weakness. </li>
           <li> Do not pass casteist, anti religious, or racial comments. </li>
           <li> Do not be arrogant or unreasonable. </li>
           <li> Do not copy or cheat in exams or anywhere else. </li>
           <li> Do not steal. </li>
           <li> Do not harbour revenge or grudge. </li>
           <li> Do not yield to jealousy and pettiness. </li>
           <li> Do not give up in the face of failures; try it until you succeed. </li>
           <li> Do not offend anybody; and do not hesitate to say ‘I am sorry’, should you have committed a mistake. </li>
           <li> Do not interrupt anybody while in conversation and wait till your turn comes, with due pardon. </li>
           <li> Do not stare inside while passing through open doors or windows. </li>
           <li> Never tell a lie nor ever indulge in rumour mongering. </li>
      </ul>
  </div>
   <div className="col-lg-6 col-md-12 col-12 bg3">
          <h6>Forbidden Practices:-</h6>
          <p>No student shall indulge in any of the following practices:-</p>
          <ul className="mainlist">
          <li>Violence in any form.</li>
          <li>Sex related Offenses.</li>
          <li>Rude and disorderly behaviour.</li>
          <li>Smoking.</li>
          <li>Use of drugs or intoxicants.</li>
          <li>Any form of gambling.</li>
          <li>Spitting in or near School building except in any spittoon provided by the school.</li>
          <li>Wallowing in casteism, communalism and practice of untouchability.</li>
          </ul>
          <p>Any body found indulging in forbidden practices will be liable to strict disciplinary action including dismissal.</p>
          </div>
</div>
</div>
</div>
     </>
  )
}

export default Conduct
