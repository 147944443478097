import  Styled  from 'styled-components'

const TBHeading = Styled.div`
  p{

    font-size: 24px;
color: #c5a637;
margin-bottom: 6px;
font-family: 'Oleo Script', system-ui;
letter-spacing: 1px;
  }
    h2{
      font-size: 35px;
       color: #1c1b3b;
       margin-bottom: 10px;
    }
    @media (max-width: 1600px){
   h2 {
    font-size: 32px;
   }
   p {
    font-size: 20px;
    }
}
@media (max-width: 1200px){
  h2 {
      font-size: 27px;
      }
}
`
export default TBHeading