import React from 'react'
import { Link } from 'react-router-dom'
import interfacegif from'../Assets/Images/interface.gif'
const AlumniRegistration = () => {
  return (
    <>
    <div className="alumni-register">
        <p><Link to="/" target="_blank">Alumni <span>Registration</span></Link></p>
        <p><Link to="/"><img src={interfacegif} alt="Sacred Heart Convent School, Jagraon"/></Link></p>
   </div>
    </>
  )
}

export default AlumniRegistration
