import React from 'react'
import { Link } from 'react-router-dom'
const Achivements = () => {
  return (
   <>
   <div className="innerslide">
   <ul className="breadcrumb">
              <li><Link to="/home"> Home</Link> </li>
    <li> Achivements </li>
</ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
       <div className="col-lg-12">
             <h1> Achivements </h1>
        </div>
     </div>
         <div className="row">
     <div className="col-lg-12">
            <div className="quotes">
                 <p><b>Data will be updated soon</b></p>
            </div>
     </div>
</div>
</div>
</div>
   </>
  )
}

export default Achivements
