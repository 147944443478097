import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNews } from '../Service/Api';

const News = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await getNews();
      setData(datas);
    };
    getData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const monthsOfYear = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
  ];

  const handleMonth = (month) => {
    return monthsOfYear[month - 1];
  };

  const emptyArray = [
    { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
    { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },

  ];

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
        <li><Link to="/home"> Home</Link> </li>
          <li>News</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>News</h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
           <div className="col-md-3">
               <div className="count-val">
                   <p>Total Count: </p>
               </div>
           </div>
           <div className="col-md-3">
               <div className="month-selection">
                  <select className="yearSelection">
                       <option className="2024" selected>2024</option>
                       <option className="2023">2023</option>
                   </select>
               </div>
           </div>
           <div className="col-md-3">
               <div className="month-selection">
                   <select className="monthSelect">
                       <option value="0">All Month</option>
                       <option value="1">Jan</option>
                       <option value="2">Feb</option>
                       <option value="3">Mar</option>
                       <option value="4">Apr</option>
                       <option value="5">May</option>
                       <option value="6">Jun</option>
                       <option value="7">Jul</option>
                       <option value="8">Aug</option>
                       <option value="9">Sep</option>
                       <option value="10">Oct</option>
                       <option value="11">Nov</option>
                       <option value="12">Dec</option>
                   </select>
               </div>
           </div>
           <div className="col-md-3">
               <div className="searchBox">
                   <input type="text" id="myInput" name="name" autocomplete="off" placeholder="Search Here.." />
               </div>
           </div>
       </div>
          <div className="row years year2024 showYear">
            <div className="newscountbox">
            {data && data.length > 0 ? data.map((item, index) => (
                <div key={index} className="newsCount">
                  <div className="news-block">
                    <div className="news-head">
                      <h4>{new Date(item.fromDate).getDate()} | {handleMonth(new Date(item.fromDate).getMonth())}</h4>
                      <h3>{item.title}</h3>
                    </div>
                    <div className="news-content">
                      <p>{item.description}</p>
                    </div>
                    <div className="attachment">
                      <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                    </div>
                  </div>
                </div>
              ))
          
              :emptyArray.map((item, index) => (
                <div key={index} className="newsCount">
                  <div className="news-block">
                    <div className="news-head">
                      <h4>{item.day} | {item.month}</h4>
                      <h3>{item.title}</h3>
                    </div>
                    <div className="news-content">
                      <p>{item.description}</p>
                    </div>
                    <div className="attachment">
                      <Link to={item.attachments} target="_blank"><i className="bi bi-paperclip"></i></Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="load-more">
              <div className="next">Show More</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
