import React from 'react'
import { Link } from 'react-router-dom'
const OurMotto = () => {
  return (
    <>
                <div className="innerslide">
                <ul className="breadcrumb">
                          <li><Link to="/home"> Home</Link> </li>
                <li>About Us</li>
                <li>Our Motto</li>
            </ul>
            </div>
            <div className="innersec">
            <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Our Motto</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                        <div className="quotes">
                            <p><b>“The scholastic as well as co-scholastic pursuits of our school strive towards blossoming of the virtues of Love Wisdom and Service in the young generation.”</b></p>
                        </div>
                </div>
            </div>
            <div className="motobox">
                <div className="motoboxfst">
                        <h4> Vision</h4>
                    <p>The creation of a new society of wholesome individuals, families and communities by empowering the Sacred Heartians to excel in all their endeavors, fostering their unique potentialities, and equipping them with skills for life in a fast changing world scenario.</p>
                </div>
                    <div className="motoboxfst">
                    <h4>Mission</h4>
            <p>Our educational apostolate endorses participatory and integrated approach to the formation of a progressive and humane society that thrives on unbounded faith in God.</p>
                    </div>
            </div>
            <ul className="mainlist">
            <li>Spiritual formation based on the Gospel values as taught by the Sacred Heart of Jesus.</li>
            <li>Optimal and enriching learning environmen.</li>
            <li>Produce and nurture critical as well as creative thinking.</li>
            <li>Practice-oriented learning methodology.</li>
            </ul>
            </div>
            </div>
    </>
  )
}

export default OurMotto
